import { useEffect, useState } from 'react';

import { getQueryParams } from '@/utility/domHelpers';

import { getCommunityPricingByDiscountCodeService } from '@/services/communitiesService';

function useEntityPrice({ communityCode, entityId, entityType }) {
  const [discountedCodeData, setDiscountedCodeData] = useState({});

  useEffect(() => {
    const { discountCode, upsellIdentityCode } = getQueryParams();
    const getDiscountedPrice = async () => {
      const params = {
        discountCode,
        communityCode: communityCode,
        entityObjectId: entityId,
        entityType: entityType
      };

      if (upsellIdentityCode) {
        params.upsellIdentityCode = upsellIdentityCode;
      }

      const { data, error } =
        await getCommunityPricingByDiscountCodeService({
          discountCode,
          communityCode: communityCode,
          entityObjectId: entityId,
          entityType: entityType,
          upsellIdentityCode
        });
      if (error) {
        return;
      }
      setDiscountedCodeData(data);
    };

    if (discountCode && discountCode.length > 0) {
      getDiscountedPrice();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { discountedCodeData };
}

export default useEntityPrice;
